<template>
  <p-drawer
    :steps="easyTradeStep"
    @on-close="smartRouter.exit()"
    @on-header-click="isShowMarketList"
  >
    <template #step1>
      <div class="easy-trade__wrapper">
        <p-segmented-control
          id="tradeDirection"
          v-model="tradeDirection"
          size="lg"
          :items="tradeControl"
          @on-change="selectedSegment"
        />
        <div class="easy-trade__form">
          <div :class="['easy-trade__input--wrapper']">
            <div class="easy-trade__currency">
              <div class="easy-trade__currency--value">
                <input
                  v-bind="fieldAttributes"
                  id="easyTrade"
                  v-model="inputValue"
                  autocomplete="off"
                  required
                  @input="sanitizeInput"
                />
                <p class="f-title-24-bold t-passive-states-disabled-fg" v-text="payment" />
              </div>
              <span
                class="f-utility-14 t-fg-tertiary"
                v-text="`≈ ${calcConvertedValue} ${convertedPayment}`"
              />
            </div>
            <div class="easy-trade__direction">
              <p-icon
                icon="switch-vertical"
                color="fg-primary"
                class="cursor-pointer"
                size="xmd"
                @on-click="switchCurrency"
              />
            </div>
          </div>
          <div class="easy-trade__action">
            <div>
              <div class="easy-trade__balance">
                <span class="t-fg-tertiary f-utility-14" v-text="$t('easyTrade.balance')" />
                <p class="t-fg-primary f-utility-14-bold" v-text="balance" />
              </div>
              <div v-if="isUserAuthenticated()">
                <div
                  v-if="!isBalanceCheck"
                  class="t-fg-danger flex flex-row f-utility-14 place-items-center gap-8 mb-8 cursor-pointer"
                >
                  {{
                    $t(tradeIsBuy ? 'easyTrade.balanceNotEnough' : 'easyTrade.balanceNot', {
                      currency: tradeIsBuy ? 'TL' : selectedMarket?.pairs?.market.toUpperCase(),
                    })
                  }}
                  <div v-if="tradeIsBuy" class="place-items-center gap-6" @click="nextStepHandler">
                    <span class="f-utility-14-bold t-fg-primary" v-text="$t('wallet.depositTL')" />
                    <p-icon icon="caret-right" size="xs" color="fg-accent" />
                  </div>
                </div>
              </div>
              <div class="easy-trade__chips">
                <p-chip
                  v-for="(item, index) in fastChipInputs"
                  :key="item + index"
                  :label="item"
                  :selectable="false"
                  class="easy-trade__chips--item"
                  @click="fastChipClicked(item)"
                />
              </div>
            </div>
            <p-button
              :loading="isLoading"
              :status="orderButtonColor"
              :type="!isUserAuthenticated() ? 'button' : 'submit'"
              block="true"
              class="mb-16"
              @click.prevent.stop="formSubmit"
            >
              {{ buttonName }}
            </p-button>
          </div>
        </div>

        <div v-if="showMarketList" class="easy-trade__dropdown" @click.stop>
          <currency-search-list
            v-if="tradeIsBuy"
            :selected-market="currentMarket"
            :except-all="true"
            :list-filter="true"
            @market-selected="onMarketSelected"
          />
          <user-assets-search-list
            v-else
            :selected-market="currentMarket"
            @market-selected="onMarketSelected"
          />
        </div>
      </div>
    </template>
    <template v-if="isUserAuthenticated()" #step1-actions>
      <p-icon
        icon="paper-note"
        color="fg-secondary"
        class="mr-16 place-items-center px-4 cursor-pointer"
        @click="smartRouter.go('/financial-history/buy-sell')"
      />
    </template>
    <template #step1-titleAction>
      <p-icon
        :icon="'caret-filled-down'"
        size="xmd"
        color="fg-primary"
        :class="['easy-trade__icon', showMarketList && 'easy-trade__icon--rotate']"
      />
    </template>
    <template #step2>
      <asset-item :currency="'tl'" :deposit-asset="false" />
    </template>
  </p-drawer>
</template>

<script setup lang="ts">
import type { TickerList, MarketConfig } from '~/models/main'

const isLoading = ref(false)

const tradeControl = ref([
  {
    key: 'buy',
    display: $t('general.buy'),
  },
  {
    key: 'sell',
    display: $t('general.sell'),
  },
])
const tradeDirection = ref('buy')
const selectedCurrency = ref('btc_tl')
const router = useRouter()
const route = useRoute()
const inputValue = ref<string | number>('')
const isPaymentCurrency = ref(false)
const tradeIsBuy = computed(() => tradeDirection.value === 'buy')
const tradeIsSell = computed(() => tradeDirection.value === 'sell')
const showMarketList = ref(false)

const payment = ref('')
const convertedPayment = ref('')
const isShowErrorTotalPrice = ref(false)

const mainStore = useMainStore()
const userStore = useUserStore()
const marketStore = useMarketStore()

const orderButtonColor = computed((): string => {
  return tradeIsBuy.value ? 'success' : 'danger'
})

const placeholder = '0'

const easyTradeStep = computed(() => {
  return {
    step1: {
      title: marketName.value?.toUpperCase?.(),
      url: 'trade',
      back: true,
      headerLink: true,
      description: `${lastPrice.value} ${paymentCurrency.value}`,
      headerTitleSlot: {
        icon: icon.value,
        isPicker: true,
      },
    },
    step2: {
      title: $t('general.depositCurrency', {
        currency: $t('general.tl'),
      }),
      back: true,
      close: false,
      url: 'assetItem',
    },
  }
})

const fastChipInputs = computed(() => {
  return tradeIsBuy.value
    ? ['250 TL', '500 TL', '1000 TL', '2000 TL']
    : ['25%', '50%', '75%', '100%']
})

const tickers = computed((): TickerList => {
  return mainStore.getTickers
})

const markets = computed((): MarketConfig => {
  return mainStore.getAllMarkets
})

const currentMarket = computed(() => {
  return marketList.value?.[selectedCurrency.value as keyof MarketConfig]
})

const lastPrice = computed(() => {
  return formatNumber({
    num: tickers.value[selectedCurrency.value]?.last,
    precision: markets.value[selectedCurrency.value]?.precisions?.price,
  })
})

const paymentCurrency = computed(() => {
  return markets.value[currentMarket?.value?.id]?.pairs?.payment?.toUpperCase()
})

const marketList = computed(() => {
  return mainStore.getMarketList
})

const userAssets = computed(() => {
  return userStore.getUserAssets
})

const marketName = computed(() => {
  return selectedCurrency.value.split('_')[0]
})

const selectedMarket = computed(() => {
  return markets.value?.[selectedCurrency.value] || null
})

const balance = computed(() => {
  const isSell = tradeIsSell.value
  const currency = isSell ? marketName.value : (getBaseCurrency() ?? '0')
  const precision = isSell ? selectedMarket.value?.precisions?.amount : undefined
  const total = userAssets.value?.[currency]?.available ?? 0
  const formattedValue = formatNumber({ num: total, precision })

  return `${formattedValue} ${currency?.toUpperCase?.()}`
})

const icon = computed(() => {
  return getAsset({ asset: currentMarket?.value?.icon || '', type: 'coin' })
})

const buttonName = computed(() => {
  if (!isUserAuthenticated()) {
    return $t('general.signin')
  }

  const translationKey = tradeIsBuy.value ? 'tradeForm.buy' : 'tradeForm.sell'
  const currency = marketName.value?.toUpperCase?.() ?? ''

  return $t(translationKey, { currency })
})

const isBalanceCheck = computed(() => {
  const totalAsset = tradeIsBuy.value
    ? Number(userAssets?.value.tl?.available)
    : Number(userAssets?.value[marketName.value]?.available)

  if (isNaN(totalAsset)) return ''

  const inputAmount = Number(inputValue.value)
  const price = Number(calcPrice())
  const amount = Number(calcAmount())
  const isPayment = isPaymentCurrency.value

  if (tradeIsBuy.value) {
    return isPayment ? totalAsset >= price : totalAsset >= inputAmount
  } else {
    return isPayment ? totalAsset >= inputAmount : totalAsset >= amount
  }
})

const inputStyle = computed(() => ({
  width: `${lengthCalc(inputValue.value) || 0}ch`,
  minWidth: lengthCalc(inputValue.value) ? undefined : `${lengthCalc(placeholder || 0) || 0}ch`,
}))

const fieldAttributes = computed(() => ({
  class: ['easy-trade__input--field', 'f-title-48-bold'],
  style: inputStyle.value,
  placeholder,
}))

const sanitizeInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  const value = target.value

  inputValue.value = sanitizePriceInput(value)
  target.value = inputValue.value.toString()
}

const calcConvertedValue = computed(() => {
  return isPaymentCurrency.value ? calcPrice() : calcAmount()
})

const calcAmount = () => {
  const ticker = tickers.value[selectedCurrency.value]
  if (!ticker) {
    return 0
  }
  const amount = div({
    numA: Number(inputValue.value),
    numB: Number(ticker.last),
    precision: markets.value[selectedCurrency.value]?.precisions?.amount,
  })
  return amount
}

const calcPrice = () => {
  const ticker = tickers.value[selectedCurrency.value]
  if (!ticker) {
    return 0
  }
  const price = mul({
    numA: Number(inputValue.value),
    numB: Number(ticker.last),
    precision: markets.value[selectedCurrency.value]?.precisions?.price,
  })
  return price
}

const lengthCalc = (value: string | number): number => {
  if (typeof value !== 'string' || value.length === 0) {
    return 0
  }
  let length = value.length
  const dotCommaCount = (value.match(/[.,]/g) || []).length
  length -= 0.5 * dotCommaCount

  return length
}

const fastChipClicked = (item: string) => {
  const switchIfNeeded = (condition: boolean) => {
    if (condition) {
      switchCurrency()
    }
  }

  const extractNumber = (str: string, delimiter: string) => {
    return str.split(delimiter)[0]
  }

  if (tradeIsBuy.value) {
    switchIfNeeded(isPaymentCurrency.value)
    const number = extractNumber(item, ' ')
    inputValue.value = number.toString()
    return
  }

  switchIfNeeded(!isPaymentCurrency.value)
  const percentage = Number(extractNumber(item, '%')) / 100
  const totalAsset = userAssets?.value[marketName.value]?.available
  const number = mul({
    numA: Number(totalAsset),
    numB: percentage,
    precision: selectedMarket.value?.precisions?.amount,
  })
  inputValue.value = number.toString()
}

const switchCurrency = () => {
  isPaymentCurrency.value = !isPaymentCurrency.value

  payment.value = isPaymentCurrency.value
    ? selectedMarket.value?.pairs?.market.toUpperCase()
    : selectedMarket.value?.pairs?.payment.toUpperCase()

  convertedPayment.value = isPaymentCurrency.value
    ? selectedMarket.value?.pairs?.payment.toUpperCase()
    : selectedMarket.value?.pairs?.market.toUpperCase()

  focusInput('easyTrade')
}

const nextStepHandler = () => {
  router.replace({ query: { ...route.query, step: 'assetItem' } })
}

const selectedSegment = () => {
  if (isPaymentCurrency.value && tradeDirection.value === 'buy') {
    switchCurrency()
  } else if (!isPaymentCurrency.value && tradeDirection.value === 'sell') {
    switchCurrency()
  }
}

const bestOrderPrice = computed((): string | null => {
  return marketStore.getBestOrderPrice(selectedCurrency.value, tradeDirection.value)
})

const handleResetForm = () => {
  inputValue.value = ''
}

const formSubmit = async () => {
  if (!isUserAuthenticated()) {
    smartRouter.go('/auth/sign-in')
  } else {
    isLoading.value = true
    isShowErrorTotalPrice.value = Number(inputValue.value) < 10 && true
    const lastPriceValue = Number(String(lastPrice.value)?.replace(/,/g, ''))

    const payload = {
      price: bestOrderPrice.value ? Number(bestOrderPrice.value) : lastPriceValue,
      total: tradeIsBuy ? Number(inputValue.value) : Number(calcConvertedValue.value),
      market: selectedCurrency.value,
      trade: tradeDirection.value,
      amount: tradeIsSell ? Number(inputValue.value) : Number(calcConvertedValue.value),
      type: 'easy',
    }

    useLookupsStore().fetchEventDetail({
      order: 'market',
      trade: payload?.trade,
      form_name: 'easyTrade',
      market: payload?.market,
    })

    await marketStore
      .order(payload)
      .then(() => {
        handleResetForm()
      })
      .finally(() => {
        isLoading.value = false
      })
  }
}

const focusInput = (name: string) => {
  const el = document.getElementById(name)
  if (el) el.focus()
}

const isShowMarketList = () => {
  showMarketList.value = !showMarketList.value
}

const onMarketSelected = (market: any) => {
  showMarketList.value = false
  selectedCurrency.value = market.id
}

onMounted(() => {
  focusInput('easyTrade')
  inputValue.value = ''
})

watch(
  () => selectedMarket.value,
  (val) => {
    if (val) {
      payment.value = isPaymentCurrency.value
        ? val.pairs.market.toUpperCase()
        : val.pairs.payment.toUpperCase()

      convertedPayment.value = val.pairs.market.toUpperCase()

      focusInput('easyTrade')
    }
  },
  { immediate: true },
)

watch(
  () => $route().query.tradeDirection,
  (newVal) => {
    if (newVal) {
      tradeDirection.value = newVal as string
      focusInput('easyTrade')
    }
  },
  { immediate: true },
)
</script>
