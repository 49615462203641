<template>
  <div class="full-width">
    <p-search-input
      v-model="searchQuery"
      class="pt-8 pb-16"
      size="md"
      :placeholder="$t('general.search.asset')"
    />
    <div class="alarm-search-list__history">
      <div class="mt-16">
        <div class="cursor-pointer">
          <div
            v-for="(market, index) in userAvailableAssets"
            :key="index"
            @click="selectMarketToAsset(market)"
          >
            <div class="p-menu__item">
              <div :class="['p-menu__item__inner place-items-between']">
                <div>
                  <div class="p-menu__item--title gap-12">
                    <nuxt-img
                      :src="
                        getAsset({
                          asset: isUserAuthenticated()
                            ? currency(market?.symbol)?.icon
                            : market?.icon,
                          type: 'coin',
                        })
                      "
                      width="20"
                      height="20"
                    />
                    <div>
                      <div class="place-items-start gap-4">
                        <h4
                          v-text="
                            isUserAuthenticated()
                              ? currency(market?.symbol)?.name
                              : market?.fullName
                          "
                        />
                        <p-icon
                          v-if="
                            selectedMarket?.name ===
                            (isUserAuthenticated() ? market?.symbol : market?.fullName)
                          "
                          icon="check"
                          size="xmd"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="place-items-end flex-column text-right align-items-end">
                  <div class="f-utility-12-medium place-items-start gap-2">
                    <div
                      class="t-fg-primary"
                      v-text="
                        formatNumber({
                          num: isUserAuthenticated() ? market?.available : market?.last,
                          precision: isUserAuthenticated()
                            ? getMarket(market?.symbol)?.precisions?.amount
                            : market?.precisions?.market,
                        })
                      "
                    />
                    <div
                      class="t-fg-tertiary"
                      v-text="
                        isUserAuthenticated()
                          ? market?.symbol?.toUpperCase?.()
                          : market?.name?.toUpperCase?.()
                      "
                    />
                  </div>
                  <div class="place-items-end gap-6">
                    <div :class="`f-utility-12-medium picker__item--change-${color(market)}`">
                      <span
                        v-text="market?.percentage > 0 ? '+' : market?.percentage < 0 ? '-' : ''"
                      />
                      <span v-text="formatPercent({ num: market?.percentage })" />
                    </div>

                    <span
                      v-if="isUserAuthenticated()"
                      class="f-utility-14-medium t-fg-tertiary"
                      v-text="`≈ ${calculatedTotal(market?.symbol)}`"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p-divider v-if="userAvailableAssets.length - 1 !== index" class="my-2 full-width" />
          </div>
        </div>
        <empty-state
          v-if="userAvailableAssets.length < 1"
          :title="$t('wallet.assetDetail.noResultSearch')"
          icon="coin"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ObjectRecord } from '~/models'
import type { MarketConfig, TickerList } from '~/models/main'

defineProps({
  selectedMarket: {
    type: Object as () => MarketConfig | null,
    default: null,
  },
})

const emit = defineEmits(['marketSelected'])

const userStore = useUserStore()
const mainStore = useMainStore()
const searchQuery = ref<string | number>('')

const userAvailableAssets = computed(() => {
  const userAssets = Object.values(userStore.getUsersAvailableAssets)
  const markets = Object.values(marketList.value)

  let filteredAssets = isUserAuthenticated()
    ? userAssets.filter((asset) => asset.symbol?.toLowerCase() !== 'tl')
    : markets

  if (searchQuery.value) {
    const query = String(searchQuery.value).toLowerCase()
    filteredAssets = filteredAssets.filter(
      (asset) =>
        asset.searchString?.toLowerCase().includes(query) ||
        asset.name?.toLowerCase().includes(query) ||
        asset.fullName?.toLowerCase().includes(query) ||
        asset.symbol?.toLowerCase().includes(query),
    )
  }

  filteredAssets.sort((a, b) => {
    return (a.fullName ?? '').localeCompare(b.fullName ?? '')
  })

  return filteredAssets
})

const selectMarketToAsset = (market: ObjectRecord) => {
  emit('marketSelected', getMarket(market?.symbol))
}

const color = (market: ObjectRecord) => {
  return market?.percentage > 0 ? 'success' : market?.percentage < 0 ? 'error' : 'primary'
}

const userAssets = computed(() => {
  return userStore.getUserAssets
})

const tickers = computed((): TickerList => {
  return mainStore.getTickers
})

const marketList = computed(() => {
  return mainStore.getMarketList
})

const getMarket = (currency: string) => {
  const found = $_.chain(marketList.value)
    .toPairs()
    .find(([key]) => key.split('_')[0].toLowerCase() === currency.toLowerCase())
    .value()

  return found && typeof found[1] === 'object' ? { ...found[1] } : {}
}

const currency = (currency: string) => {
  return mainStore.getCurrency(currency) || ''
}

const calculatedTotal = (market: string) => {
  const currentMarket = isUserAuthenticated()
    ? getMarket(market)
    : marketList.value[market as string]
  const marketName = `${market}_${currentMarket?.pairs?.payment}`
  const numa = Number(tickers.value?.[marketName]?.last)
  const numb = Number(userAssets.value?.[market]?.total)

  if (isNaN(numa) || isNaN(numb)) return ''

  const total = mul({
    numA: Number(tickers.value?.[marketName]?.last),
    numB: Number(userAssets.value?.[market]?.total),
    precision: currentMarket?.precisions?.price,
  })

  const formattedAmount = formatNumber({
    num: total,
    precision: currentMarket?.precisions?.price,
  })

  return `${formattedAmount} ${currentMarket?.pairs?.payment?.toUpperCase?.()}`
}
</script>
